.profile_container {
  padding: 2% 0;
  background: white;
  display: flex;
  flex-direction: column;
  /* height: 500px; */
  justify-content: space-between;
  row-gap: 10px;
}

.profile_top_container {
  /* border: 2px solid black;
  padding: 2%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; */

  /* border: 2px solid black; */
  padding: 2% 6%;
  /* width: 50%; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  column-gap: 10px;
}

.text_container {
  /* border: 2px solid yellow; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  /* width: 300px; */
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 2%;
  border-radius: 10px;
}

.text_container_bottom {
  display: flex;
  flex-direction: row;
  gap: 7px;
  border: 2px solid;
  justify-content: flex-start;
  align-items: center;
  padding: 2%;
  border-radius: 10px;
  width: 90%;
}

.name {
  font-size: 25px;
  font-weight: 1000;
}

.email_number {
  font-size: 15px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  font-weight: 600;
  align-items: flex-start;
}

.edit {
  position: absolute;
  right: 8%;
  cursor: pointer;
}

.user_carousel_section {
  /* border: 2px solid blue; */
  padding: 2%;
  /* width: 50%; */
}

.user_carousel_container {
  padding: 2% 0;
}

.user_carousel_heading {
  display: inline-block;
  position: relative;
  font-size: 13px;
  line-height: 18px;
  color: #fc692b;
  font-weight: 700;
  letter-spacing: 3px;
  margin-bottom: 10px;
  padding-left: 1%;
  text-transform: uppercase;
  color: #686ea2;
}

.user_carousel_title {
  font-size: 40px;
  font-weight: 800;
  line-height: 1.2;
}

@media only screen and (max-width: 770px) {
  .email_number {
    row-gap: 10px;
    flex-direction: column;
  }
}

@media only screen and (max-width: 350px) {
  .name {
    font-size: 7vw;
  }

  .email_number {
    font-size: 3.5vw;
    row-gap: 10px;
    flex-direction: column;
  }
  .user_carousel_heading {
    font-size: 4vw;
  }
  .user_carousel_title {
    font-size: 6vw;
  }
}
