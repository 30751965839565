/* .main {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: 50vh;
  color: white;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.smallbox {
  width: 5%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
} */
/* .box {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  scroll-behavior: smooth;
} */

.blog_container {
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* justify-content: space-between; */
}
.blog_container_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 2% 0 2% 3%; */
  padding: 2% 0;
  row-gap: 33px;
  /* border: 2px solid red; */
  width: 62%;
}

.blog_container_right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2%;
  row-gap: 33px;
  /* border: 2px solid #686ea2; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 28%;
  min-height: 400px;
  height: fit-content;
  margin-top: 2%;
  border-radius: 10px;
}

/* .card {
  height: 100%;
  margin-left: 2%;
  cursor: pointer;
  border: 2px solid black;
} */
.image {
  height: 40%;
  max-width: 340px;
  width: 55%;
  min-width: 250px;
  object-fit: cover;
}
.image:hover {
  transform: scale(1.05);
  transition: 0.5s;
}

.card_detail {
  /* position: relative;
  height: 70%;
  width: 235px;
  border-radius: 10px;
  top: -74px;
  background: white;
  margin-left: auto;
  margin-right: auto;
  color: black;
  text-align: left;
  padding: 5%;
  display: flex;
  flex-direction: column;
  row-gap: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */

  /* position: relative;  */
  /* height: 70%; */
  /* width: 59%; */
  /* width: 100%; */
  border-radius: 10px;
  /* top: -74px; */
  background: white;
  /* margin-left: auto; */
  /* margin-right: auto; */
  color: black;
  text-align: left;
  padding: 5%;
  display: flex;
  flex-direction: column;
  row-gap: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* border: 2px solid red; */
}

.button {
  border-radius: 50%;
  height: 8vh;
  width: 7vh;
  margin-top: 20vh;
  background-color: white;
  border: none;
  cursor: pointer;
}

.author {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #999999;
  column-gap: 3%;
  padding: 2% 0;
}

.time {
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  margin-left: 12px;
  text-align: center;
}

.heading {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-transform: uppercase;
  width: 90%;
}

.para {
  line-height: 1.6em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 6;
  padding: 2% 0 0 0;
  -webkit-box-orient: vertical;
}

.read_more {
  width: 90px;
  cursor: pointer;
  margin-top: 2%;
  font-size: 13px;
  font-weight: 600;
  color: #686ea2;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  background-position: 0 100%;
  background-size: 2% 2px;
  background-repeat: no-repeat;
  /* transition: color 0.2s ease-out, background-size 0.3s; */
  transition: all 0.2s;
}

.read_more:hover {
  background-image: linear-gradient(#686ea2 0 0);
  color: #686ea2;
  background-size: 100% 2px;
}
/* this is form search implementation */
.search_input {
  height: 40px;
  width: 100%;
  border: none;
  border-bottom: 2px solid #686ea2;
  text-decoration: none;
  outline: none;
  background-color: transparent;
  font-size: large;
  color: #686ea2;
}

.search_input::placeholder {
  color: #686ea2;
  opacity: 1;
}

.container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-direction: column;
  gap: 15px;
}

.card {
  /* height: 300px; */
  /* width: 250px; */
  /* border: 2px solid black; */
  color: black;
}
.card p {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  color: black;
  text-decoration: none;
  display: inline-block;
  background-position: 0 100%;
  background-size: 2% 2px;
  background-repeat: no-repeat;
}
.card p:hover {
  transition: all 0.3s ease-in;
  font-weight: 600;
  color: #686ea2;
  background-image: linear-gradient(#686ea2 0 0);
  background-size: 100% 2px;
}
/*  */

@media only screen and (max-width: 990px) {
  .card_detail{
    width: 90%;
  }
  .blog_container_left {
    width: 90%;
  }
  .blog_container_right {
    display: none;
  }
}

@media only screen and (max-width: 315px) {
  .image {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}
