.contact_overlay_div_container {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.6;
  background: black;
  width: 100%;
  z-index: 101;
}

.contact_overlay_form_div {
  position: fixed;
  height: 500px;
  width: 60%;
  left: 15vw;
  top: 10%;
  background: white;
  z-index: 11;
  padding: 2% 5% 2% 5%;
  border-radius: 10px;
  z-index: 102;
  overflow-y: scroll;
}

.contact_overlay_cross_button {
  position: absolute;
  right: 10px;
  top: 10px;
}
