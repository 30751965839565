.scroll_to_top {
  /* font-size: 40px; */
  text-align: center;
  animation: scroll_to_top 1s ease-in-out infinite alternate;
  background: #686ea2;
  background-color: white;
  position: fixed;
  bottom: 10%;
  right: 4%;
  height: 60px;
  width: 60px;
  color: black;
  /* border: 2px solid; */
  border-radius: 50%;
  border: none;
  cursor: pointer;
  z-index: 100;
  box-shadow: none;
  transition: background-color 0.6s, box-shadow 6s;
}

@keyframes scroll_to_top {
  from {
    box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #686ea2, 0 0 40px #686ea2,
      0 0 50px #686ea2, 0 0 60px #686ea2, 0 0 70px #686ea2;
    background-color: #686ea2;
  }

  to {
    box-shadow: 0 0 20px #fff, 0 0 30px #515bb2, 0 0 40px #515bb2,
      0 0 50px #515bb2, 0 0 60px #515bb2, 0 0 70px #515bb2, 0 0 80px #515bb2;
    background-color: #515bb2;
  }
}

.scroll_to_top img {
  max-width: 100%;
  transition: max-width 0.6s;
}

.scroll_to_top:hover {
  background-color: #686ea2;
  animation: none;
  box-shadow: 0px 2px 9px 12px #686ea2;
  transition: box-shadow 6s;
}

.scroll_to_top img:hover {
  filter: invert(1);
  max-width: 80%;
}

@media screen and (min-width: 1636px) {
  .scroll_to_top {
    position: sticky;
    margin-left: 83%;
  }
}
