.contact_overlay_div_container {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.6;
  background: black;
  width: 100%;
  z-index: 101;
}

.contact_overlay_form_div {
  position: fixed;
  height: 400px;
  /* width: 40%;
  left: 25vw; */
  width: 500px;
  left: 30%;
  top: 14%;
  background: white;
  z-index: 11;
  /* padding: 2% 5% 2% 5%; */
  border-radius: 10px;
  z-index: 102;
}

.contact_overlay_cross_button {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 5%;
  filter: invert(1);
  cursor: pointer;
}

/* body {
  align-items: center;
  background-color: #000;
  display: flex;
  justify-content: center;
  height: 100vh;
} */
.form_container {
  background-image: url(http://finix.powersquall.com/it-solution/wp-content/uploads/sites/9/2021/09/section-patter-2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 2% 0%;
}

.form {
  margin-left: auto;
  margin-right: 10%;
  /* margin-right: auto; */
  background-color: #686ea2;
  border-radius: 10px;
  box-sizing: border-box;
  /* height: 500px; */
  padding: 20px;
  width: 100%;
}

.title {
  color: #303245;
  font-family: sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-top: 30px;
}

.subtitle {
  color: #303245;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.input_container {
  height: 50px;
  position: relative;
  width: 100%;
}

.ic1 {
  margin-top: 20px;
}

.ic2 {
  margin-top: 30px;
}

.input {
  background-color: #303245;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}

.cut {
  background-color: #686ea2;
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 76px;
}

.cut_short {
  width: 50px;
}

.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
  background-color: #303245;
}

.placeholder {
  color: #65657b;
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #808097;
}

.input:focus ~ .placeholder {
  color: white;
}

.submit {
  background-color: #303245;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 38px;
  /* outline: 0; */
  text-align: center;
  width: 100%;
  transition: all 0.6s ease-in;
}

.submit:hover {
  background-color: #686ea2;
  border: 2px solid #303245;
  color: #303245;
  font-size: 20px;
  font-weight: 600;
}
