.contact_overlay_form_div {
  /* position: fixed;
  height: 500px;
  width: 60%;
  left: 15vw;
  top: 10%;
  background: white;
  z-index: 11;
  padding: 2% 5% 2% 5%;
  border-radius: 10px;
  z-index: 102; */

  position: fixed;
  height: 100%;
  width: 96%;
  top: 0%;
  background: white;
  z-index: 11;
  padding: 0% 2%;
  z-index: 102;
}

.contact_overlay_cross_button {
  position: absolute;
  right: 10px;
  top: 10px;
}

.form_container {
  background-image: url(http://finix.powersquall.com/it-solution/wp-content/uploads/sites/9/2021/09/section-patter-2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 2% 0%;
}

.form {
  /* margin-left: auto;
  margin-right: 10%;
  margin-right: auto;
  background-color: #15172b;
  border-radius: 20px;
  box-sizing: border-box;
  height: 500px;
  padding: 20px;
  width: 400px; */
  /* border: 2px solid red; */
  border-radius: 20px;
  box-sizing: border-box;
  height: 100%;
}

.title {
  /* color: #eee; */
  font-family: sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-top: 30px;
}

.subtitle {
  /* color: #eee; */
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.input_container {
  height: 50px;
  position: relative;
  width: 100%;
}
.input_para_container {
  /* border: 2px solid red; */
  height: 65%;
  position: relative;
  width: 100%;
}

.ic1 {
  margin-top: 20px;
}

.input {
  border-radius: 12px;
  border: 1px solid;
  box-sizing: border-box;
  color: black;
  font-size: 18px;
  height: 100%;
  width: 97%;
  padding: 1rem;
}

.placeholder {
  color: #65657b;
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.submit {
  margin-top: 1%;
  background-color: #08d;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  /* margin-top: 38px; */
  /* outline: 0; */
  text-align: center;
  width: 97%;
}

.wrapper_class {
  /* border: 2px solid blue; */
  padding: 1rem;
  height: 77%;
  /* border: 1px solid #ccc; */
}
.editor_class {
  background-color: lightgray;
  height: 83%;
  padding: 1rem;
  /* border: 1px solid #ccc; */
  /* border: 2px solid yellow; */
}
.toolbar_class {
  /* border: 1px solid #ccc; */
  /* border: 2px solid red; */
  height: 21%;
  overflow: scroll;
  scroll-behavior: smooth;
}

/* @media only screen and (max-width: 860px) {
  .editor_class {
    height: 70%;
  }
  .toolbar_class {
    height: 30%;
  }
} */
