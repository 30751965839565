/* .open {
  display: none;
  height: 100vh;
  border: 2px solid red;
  position: fixed;
  width: 100%;
  top: 0;
  transition: all 0.6s ease-in-out;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: fixed;
  bottom: 0px;
  min-height: 100px;
  width: 100%;
  padding: 20px 10px;
  z-index: 100;
} */
.open {
  background-image: radial-gradient(
    circle farthest-corner at center,
    #686ea2 0%,
    #1c262b 100%
  );
  display: block;
  height: 100vh;
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.6s ease-in-out;
  z-index: 100;
  overflow: hidden;
  /* border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: fixed;
    bottom: 0px;
    min-height: 100px;
    width: 100%;
    padding: 20px 10px;
    */
}

.search_input {
  height: 40px;
  width: 500px;
  margin: 100px;
  border: none;
  border-bottom: 2px solid white;
  text-decoration: none;
  outline: none;
  background-color: transparent;
  font-size: large;
  color: white;
}

.search_input::placeholder {
  color: white;
  opacity: 1;
}

.cancel {
  position: absolute;
  right: 4%;
  top: 3%;
  font-size: x-large;
  filter: sepia(1) saturate(100);
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}
.cancel:hover {
  transform: rotate(360deg);
  right: 4.2%;
  top: 3.2%;
  font-size: 40px;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* border: 2px solid red; */
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-evenly;
  row-gap: 30px;
}

.card {
  height: 300px;
  width: 250px;
  border: 2px solid white;
  color: white;
}
