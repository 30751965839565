.post_container {
  padding: 2% 0%;
  width: 90%;
  background: white;
  /* border: 2px solid red; */
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  row-gap: 13px;
}

.image_container {
  width: 326px;
  /* height: 217px; */
  /* border: 2px solid black; */
}

.image_container img {
  max-width: 100%;
  object-fit: contain;
}

.heading_detail {
  display: flex;
  flex-direction: row;
  column-gap: 3%;
  align-items: center;
  color: #999999;
  font-size: 14px;
  text-transform: capitalize;
}
.title {
  text-align: left;
  font-weight: bolder;
  color: #757575;
  font-size: 16px;
}

.paragraph,
.post_container p,
.post_container ul {
  text-align: left;
  color: #757575;
}
