.main {
  /* border: 2px solid rgb(158, 54, 54); */
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* height: 37vh; */
  /* height: 50vh; */
  /* background-color: #171a29; */
  color: white;
}

.container {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  /* border: 2px solid red; */
  justify-content: space-evenly;
}

.card {
  margin-left: 2%;
  /* border: 2px solid red; */
}

.image {
  /* border: 2px solid black; */
  /* padding: 3% 0%; */
  height: 100px;
  aspect-ratio: 1/1;
  /* max-width: 100px; */

  object-fit: contain;
}
.image:hover {
  transform: scale(1.05);
  transition: 0.5s;
}

.title {
  color: "red";
}
