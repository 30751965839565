.mid_container {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 2% 0%;
}
.h1 {
  padding: 5% 0%;
}

.ul {
  padding-left: 7%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
