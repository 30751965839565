.main {
  /* border: 2px solid rgb(158, 54, 54); */
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* height: 37vh; */
  /* height: 50vh; */
  /* background-color: #171a29; */
  color: white;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.smallbox {
  /* width: 5%; */
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  /* border: 2px solid red; */
}
.box {
  /* border: 2px solid black; */
  width: 90%;
  /* margin-left: auto; */
  /* margin-right: auto; */
  display: flex;
  flex-direction: row;
  overflow: hidden;
  scroll-behavior: smooth;
}
.card {
  margin-left: 2%;
  /* border: 2px solid red; */
}

.image {
  /* border: 2px solid black; */
  /* padding: 3% 0%; */
  height: 100px;
  max-width: 100px;
  object-fit: contain;
}
.image:hover {
  transform: scale(1.05);
  transition: 0.5s;
}

.button {
  /* border: 2px solid black; */
  border-radius: 50%;
  /* height: 8vh;
  width: 7vh;
  margin-top: 4vh; */
  margin-top: 40px;
  /* width: 130px;
  height: 130px; */
  background-color: white;
  border: none;
  cursor: pointer;
  /* border: 2px solid red; */
}
