.button_edit_container {
  cursor: pointer;
  font-size: 20px;
  color: white;
  background: #686ea2;
  border: none;
  padding: 1%;
  border-radius: 10px;
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  transition: font-size 1s, background 1s, font-size 1s;
}
.button_edit_container:hover {
  background: white;
  font-weight: 600;
  border: 2px solid;
  color: #686ea2;
  /* font-size: 25px; */
}

.button_delete_container {
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  color: white;
  background: red;
  border: none;
  padding: 1%;
  border-radius: 10px;
  gap: 5px;
  display: flex;
  flex-direction: row;
  width: 150px;
  height: 50px;
  align-items: center;
  justify-content: center;
  transition: font-size 1s, background 1s, font-size 1s;
}

.button_delete_container:hover {
  background: white;
  font-weight: 600;
  border: 2px solid;
  color: red;
  /* font-size: 25px; */
}
