.box {
  display: flex;
  flex-direction: row;
  background: white;
  padding: 2% 0;
  gap: 25px;
  flex-wrap: wrap;
}

.image {
  height: 40%;
  width: 36vh;
  object-fit: cover;
}
.image:hover {
  transform: scale(1.05);
  transition: 0.5s;
}

.card {
  cursor: pointer;
  height: 250px;
  width: 235px;
  background: white;
  margin-left: 2%;
  padding: 2%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 2%;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
}

.author {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 3%;
}

.time {
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  margin-left: 12px;
  text-align: center;
}

.heading {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-transform: uppercase;
  width: 90%;
}

.para {
  line-height: 1.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 7;
  -webkit-box-orient: vertical;
}

.read_more {
  width: 90px;
  cursor: pointer;
  margin-top: 2%;
  font-size: 13px;
  font-weight: 600;
  color: #686ea2;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  background-position: 0 100%;
  background-size: 2% 2px;
  background-repeat: no-repeat;
  /* transition: color 0.2s ease-out, background-size 0.3s; */
  transition: all 0.2s;
}

.read_more:hover {
  background-image: linear-gradient(#686ea2 0 0);
  color: #686ea2;
  background-size: 95% 1.5px;
}
