.main {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* height: 50vh; */
  height: 360px;
  color: white;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.smallbox {
  /* width: 5%; */
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}
.box {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  scroll-behavior: smooth;
}

.contact_us_box {
  width: 90%;
  height: 80%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  scroll-behavior: smooth;
}
.card {
  height: 100%;
  margin-left: 2%;
  cursor: pointer;
}
.contact_us_card {
  /* margin-left: 2%; */
  cursor: pointer;
  /* border: 2px solid black; */
  padding: 0% 2%;
}
.image {
  height: 40%;
  /* width: 36vh; */
  width: 300px;
  object-fit: cover;
}
.image:hover {
  transform: scale(1.05);
  transition: 0.5s;
}

.card_detail {
  position: relative;
  /* height: 70%; */
  width: 235px;
  border-radius: 10px;
  top: -74px;
  background: white;
  margin-left: auto;
  margin-right: auto;
  color: black;
  text-align: left;
  padding: 5%;
  display: flex;
  flex-direction: column;
  row-gap: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.contact_us_card_detail {
  position: relative;
  height: 90%;
  width: 235px;
  border-radius: 10px;
  /* top: -74px; */
  background: white;
  margin-left: auto;
  margin-right: auto;
  color: black;
  text-align: left;
  padding: 5%;
  display: flex;
  flex-direction: column;
  row-gap: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.button {
  border-radius: 50%;
  /* height: 8vh;
  width: 7vh;
  margin-top: 20vh;
   */
  /* height: 130px;
  width: 130px; */
  margin-top: 100px;
  background-color: white;
  border: none;
  cursor: pointer;
}

.author {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 3%;
}

.time {
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  margin-left: 12px;
  text-align: center;
}

.heading {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-transform: uppercase;
  width: 90%;
}

.para {
  line-height: 1.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 7;
  -webkit-box-orient: vertical;
}
